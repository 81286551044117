<template>
  <div class="w1004">
    <div class="pagenamew">
    </div>
    <div class="mybetwrap">
      <ul class="tabs">
        <template v-for="(game, index) in sportCategory">
          <li v-if="game.name" v-bind:key="index"
              class="tab-link"
              :class="{'current' : game.value === currentTabValue}"
              :data-tab="'tab-' + (index+1)"
              @click="changeTab(game.value)"
              @mouseover="game.imgs=!game.imgs"
          >
            <!-- {{game.imgs}} -->
            <!-- <em :class="{game.imgs? 'on' : ''},{game.value}"></em> -->
            <em :class="[game.value, game.imgs? 'on' : '']" ></em>
            <span>{{game.name}}</span>
          </li>
        </template>
      </ul>

      <div id="tab-1" class="tab-content current">
        <h4 class="rulestxt">모든 프로축구는 해당 리그의 공식 경기 결과대로 반영됩니다.</h4>
        <div class="board mb20">
          <ul class="boardw first">
            <li class="w150">베팅타입</li>
            <li class="w150">적용시간</li>
            <li class="">베팅홈</li>
          </ul>
          <ul class="boardw">
            <li class="w150">승무패</li>
            <li class="w150">연장없음</li>
            <li class="textleft">모든 프로축구는 해당 리그의 공식 경기 결과대로 반영됩니다.</li>
          </ul>
          <ul class="boardw">
            <li class="w150">승무패(전반전)</li>
            <li class="w150">연장제외</li>
            <li class="textleft">모든 프로축구는 해당 리그의 공식 경기 결과대로 반영됩니다.</li>
          </ul>
          <ul class="boardw">
            <li class="w150">승무패(후반전)</li>
            <li class="w150">연장제외</li>
            <li class="textleft">모든 프로축구는 해당 리그의 공식 경기 결과대로 반영됩니다.</li>
          </ul>
          <ul class="boardw">
            <li class="w150">핸디캡</li>
            <li class="w150">연장없음</li>
            <li class="textleft">모든 프로축구는 해당 리그의 공식 경기 결과대로 반영됩니다.</li>
          </ul>
          <ul class="boardw">
            <li class="w150">언더오버</li>
            <li class="w150">연장없음</li>
            <li class="textleft">모든 프로축구는 해당 리그의 공식 경기 결과대로 반영됩니다.</li>
          </ul>
        </div>

        <div class="board">
          <ul class="boardw first">
            <li class="w270">타입별 경기시간</li>
            <li class="">결과적용기준</li>
          </ul>
          <ul class="boardw">
            <li class="w270"><span class="mr10">연장제외</span><span>연장없음</span></li>
            <li class="textleft">모든 프로축구는 해당 리그의 공식 경기 결과대로 반영됩니다.</li>
          </ul>
          <ul class="boardw">
            <li class="w270">핸디캡(전체)</li>
            <li class="textleft">모든 프로축구는 해당 리그의 공식 경기 결과대로 반영됩니다.</li>
          </ul>
          <ul class="boardw">
            <li class="w270">언더오버(전체)</li>
            <li class="textleft">모든 프로축구는 해당 리그의 공식 경기 결과대로 반영됩니다.</li>
          </ul>
        </div>

      </div><!-- tab-1 -->

      <div id="tab-2" class="tab-content">

      </div><!-- tab-2 -->

      <div id="tab-3" class="tab-content">

      </div><!-- tab-3 -->

      <div id="tab-4" class="tab-content">

      </div><!-- tab-4 -->

      <div id="tab-5" class="tab-content">

      </div><!-- tab-5 -->

      <div id="tab-6" class="tab-content">

      </div><!-- tab-6 -->

      <div id="tab-7" class="tab-content">

      </div><!-- tab-7 -->

      <div id="tab-8" class="tab-content">

      </div><!-- tab-8 -->

      <div id="tab-9" class="tab-content">

      </div><!-- tab-9 -->
    </div><!-- mybetwrap -->
  </div>
</template>

<script>
import { SPORT_GAME_CATEGORY2 } from '@/libs/constants'

export default {
  name: 'BettingGuideSport',
  data () {
    return {
      sportCategory: SPORT_GAME_CATEGORY2,
      currentTabValue: SPORT_GAME_CATEGORY2[0].value
    }
  },
  methods: {
    changeTab (value) {
      this.currentTabValue = value
    }
  }
}
</script>
<style scoped>
.soccer {background: url("~@/assets/img/soccer_off.png") center no-repeat;}
.basketball {background: url("~@/assets/img/basketball_off.png") center no-repeat;}
.baseball {background: url("~@/assets/img/baseball_off.png") center no-repeat;}
.volleyball {background: url("~@/assets/img/vollyball_off.png") center no-repeat;}
.tennis {background: url("~@/assets/img/tennis_off.png") center no-repeat;}
.hockey {background: url("~@/assets/img/hockey_off.png") center no-repeat;}
.handball {background: url("~@/assets/img/handball_off.png") center no-repeat;}
.football {background: url("~@/assets/img/rugby_off.png") center no-repeat;}
.esport {background: url("~@/assets/img/esports_off.png") center no-repeat;}
li:hover .soccer, .current .soccer {background: url("~@/assets/img/soccer_on.png") center no-repeat;}
li:hover .basketball, .current .basketball {background: url("~@/assets/img/basketball_on.png") center no-repeat;}
li:hover .baseball, .current .baseball {background: url("~@/assets/img/baseball_on.png") center no-repeat;}
li:hover .volleyball, .current .volleyball {background: url("~@/assets/img/vollyball_on.png") center no-repeat;}
li:hover .tennis, .current .tennis {background: url("~@/assets/img/tennis_on.png") center no-repeat;}
li:hover .hockey, .current .hockey {background: url("~@/assets/img/hockey_on.png") center no-repeat;}
li:hover .handball, .current .handball {background: url("~@/assets/img/handball_on.png") center no-repeat;}
li:hover .football, .current .football {background: url("~@/assets/img/rugby_on.png") center no-repeat;}
li:hover .esport, .current .esport {background: url("~@/assets/img/esports_on.png") center no-repeat;}
.mybetwrap .tabs li em {width: 27px;height: 23px;display: block;margin: 0 auto;margin-bottom: 7px;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
